import { __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { Picture } from '@wix/wix-vod-shared/dist/src/common/components/picture';
import ResizedImage from 'shared/components/resized-image/resized-image';
var _propTypes = {
    thumbnailUrl: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    className: PropTypes.string,
    isResponsive: PropTypes.bool,
};
var _defaultProps = {
    width: 320,
    height: 180,
};
var SimpleThumbnail = /** @class */ (function (_super) {
    __extends(SimpleThumbnail, _super);
    function SimpleThumbnail() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SimpleThumbnail.prototype.render = function () {
        var _a = this.props, thumbnailUrl = _a.thumbnailUrl, className = _a.className, width = _a.width, height = _a.height, isResponsive = _a.isResponsive;
        if (isResponsive) {
            return React.createElement(Picture, { src: thumbnailUrl, withFallbackColor: true });
        }
        return (React.createElement(ResizedImage, { src: thumbnailUrl, className: className, withFallbackColor: true, width: width, height: height }));
    };
    SimpleThumbnail.propTypes = _propTypes;
    SimpleThumbnail.defaultProps = _defaultProps;
    return SimpleThumbnail;
}(React.Component));
var SimpleVideoThumbnail = /** @class */ (function (_super) {
    __extends(SimpleVideoThumbnail, _super);
    function SimpleVideoThumbnail() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SimpleVideoThumbnail.prototype.render = function () {
        var _a = this.props, _b = _a.video, coverUrl = _b.coverUrl, customCoverUrl = _b.customCoverUrl, width = _a.width, height = _a.height, className = _a.className;
        return (React.createElement(SimpleThumbnail, { thumbnailUrl: customCoverUrl || coverUrl, className: className, width: width, height: height }));
    };
    SimpleVideoThumbnail.propTypes = {
        video: PropTypes.object.isRequired,
        width: PropTypes.number,
        height: PropTypes.number,
        className: PropTypes.string,
    };
    SimpleVideoThumbnail.defaultProps = _defaultProps;
    return SimpleVideoThumbnail;
}(React.Component));
export { SimpleVideoThumbnail };
var ResponsiveSimpleVideoThumbnail = /** @class */ (function (_super) {
    __extends(ResponsiveSimpleVideoThumbnail, _super);
    function ResponsiveSimpleVideoThumbnail() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ResponsiveSimpleVideoThumbnail.prototype.render = function () {
        var _a = this.props, _b = _a.video, coverUrl = _b.coverUrl, customCoverUrl = _b.customCoverUrl, width = _a.width, height = _a.height, className = _a.className;
        return (React.createElement(SimpleThumbnail, { thumbnailUrl: customCoverUrl || coverUrl, className: className, width: width, height: height, isResponsive: true }));
    };
    ResponsiveSimpleVideoThumbnail.propTypes = {
        video: PropTypes.object.isRequired,
        width: PropTypes.number,
        height: PropTypes.number,
        className: PropTypes.string,
    };
    ResponsiveSimpleVideoThumbnail.defaultProps = _defaultProps;
    return ResponsiveSimpleVideoThumbnail;
}(React.Component));
export { ResponsiveSimpleVideoThumbnail };
